<template>
    <div>
        <app-message-dialog v-if="successMessage" @input="successMessage = false">
            <template v-slot:title>
                Success
            </template>
            Your file was uploaded successfully
        </app-message-dialog>
        <app-message-dialog v-if="errorMessage" @input="errorMessage = false">
            <template v-slot:title>
                Error
            </template>
            There was an error uploading your file
        </app-message-dialog>
        <div class="d-flex flex-nowrap align-center justify-center list-complete-item mr-0">
            <v-file-input
                :label="label"
                :placeholder="placeholder"
                :value="selectedFile"
                :dense="isDense"
                :disabled="isDisabled || isReadonly"
                :clearable="false"
                @change="onReportUploaded" />
            <v-btn
                v-if="!isDeleteDisabled"
                text
                small
                fab
                @click="onReportUploaded(null)">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </div>
        <v-progress-circular
            v-if="isLoading"
            :size="40"
            :width="7"
            indeterminate
            color="tertiary" />
    </div>
</template>

<script>
import axios from 'axios'

export default {

    components: {
        appMessageDialog: () => import('@/components/AppMessageDialog')
    },
    props: {
        value: {
            type: String,
            default: null
        },
        label: {
            type: String,
            default: null
        },
        filter: {
            type: Object,
            default: null
        },
        isReadonly: {
            type: Boolean,
            default: false
        },
        isDense: {
            type: Boolean,
            default: false
        },
        isDisabled: {
            type: Boolean,
            default: false
        },
        isDeleteDisabled: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            isLoading: false,
            placeholder: "",
            successMessage: false,
            errorMessage: false,
            selectedFile: null
        }
    },
    watch: {
        value: {
            immediate: true,
            handler() {
                this.previouslyUploadedFile();
            }
        }
    },

    methods: {
        async onReportUploaded(file) {
            if (file === null) {
                this.$emit('input', null);
                this.placeholder = "";
                this.selectedFile = null;
                return;
            }
            let formData = new FormData();
            formData.append("file", file);
            this.isLoading = true;
            let name = file.name.split('.')[0];
            let extension = "." + file.name.split('.')[1];
            formData.append("name", name);
            formData.append("extension", extension);
            if (!this.isEmpty(this.filter)) {
                formData.append("memberId", this.filter.memberId);
            }
            let fileResponse = await axios.post('/api/Files/', formData);
            let fileData = fileResponse.data;
            this.$emit('input', fileData.id);

            this.successMessage = true;
            this.isLoading = false;
        },
        async previouslyUploadedFile() {
            if (this.value !== null && typeof this.value !== "undefined") {
                let fileResponse = await axios.get('/api/Files/' + this.value);
                this.placeholder = fileResponse.data.file.name + fileResponse.data.file.extension;
                this.selectedFile = new File([], this.placeholder, {
                    type: "text/plain",
                })
                return;
            }
            this.placeholder = "";
        }
    }
};
</script>
<style scoped>
    .file-image {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
</style>
